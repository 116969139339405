// code that used to be on the index.js page
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./../scss/base.scss";
import "./../scss/layout.scss";

import SEO from "../components/seo";
import Main from './../components/home/Main';




const NewIndexPage = () => {
  return (
    <>
      {/* Set page title */}

      {/* Define global layout */}

      {/* Set page title */}
      <SEO title="Isaac Farciert — Home" />

      {/* Set router */}

      <Main />
    </>
  )
}

export default NewIndexPage